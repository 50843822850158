const howdenGroupAzureB2cProConfig = {
  msalConfig: {
    auth: {
      clientId: '164a9fc3-9d70-4a05-bff0-1abb69e2648c',
      redirectUri: () => `${location.origin}/granted`,
      postLogoutRedirectUri: () => `${location.origin}/logout`
    }
  },
  apiConfig: {
    clientId: 'bfb3d2ab-749b-4170-bbeb-6b6367df534b',
    scopes: ['https://b2cprod01.onmicrosoft.com/web-api/webapi.read'],
    uri: 'https://b2cprod01.onmicrosoft.com/web-api'
  },
  policies: {
    names: {
      signUpSignIn: 'b2c_1a_signin_aad_howdeniberia',
      resetPassword: 'b2c_1a_password_reset_howdeniberia'
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://b2cprod01.b2clogin.com/b2cprod01.onmicrosoft.com/b2c_1a_signin_aad_howdeniberia'
      },
      resetPassword: {
        authority: 'https://b2cprod01.b2clogin.com/b2cprod01.onmicrosoft.com/b2c_1a_password_reset_howdeniberia'
      }
    },
    authorityDomain: 'b2cprod01.b2clogin.com'
  },
  protectedResources: new Map([
    ['https://b2cprod01.onmicrosoft.com/web-api', ['https://b2cprod01.onmicrosoft.com/web-api/webapi.read']]
  ])
};

export { howdenGroupAzureB2cProConfig };
